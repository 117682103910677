/*
 * @Author: 冯杰
 * @Date: 2021-08-16 09:45:40
 * @LastEditTime: 2021-09-04 11:14:07
 * @FileName: 红包变动明细
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'ChangeDetailsList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/redPacket/award/findByConditions',
      requestType: 'GET',
      params: { redPacketId: this.propsObj.id },
    };
  },
  props: {
    propsObj: Object,
  },
  components: {
    Modal,
  },
  methods: {
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('changeDetailsList');
  },
};
