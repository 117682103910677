<!--
 * @Author: 冯杰
 * @Date: 2021-08-13 14:12:07
 * @LastEditTime: 2021-11-08 14:00:07
 * @FileName: 红包余额看板
-->
<template>
  <div class="content">
    <!-- 看板 -->
    <div class="box">
      <div class="textFont">
        <span
          >最近{{ statisticsDay }}天，日均发出<span class="textColor">{{ dailyAverageday }}元</span
          >，余额预计还能发<span class="textColor">{{ predictDay }}天</span></span
        >
      </div>
      <div class="header-box">
        <div class="yve">
          <span class="test">红包余额(元)</span>
          <span class="money">{{ surplus }}</span>
        </div>
        <div class="fachu">
          <span class="test">昨日红包发出(元)</span>
          <el-link class="detailViews" @click="changeFlow">查看明细 <i class="el-icon-arrow-right"></i></el-link>
          <span class="money">{{ fachu }}</span>
        </div>
        <div class="shangzhang">
          <span class="test">昨日红包上账(元)</span>
          <el-link class="detailViews" @click="redpackBill">查看明细 <i class="el-icon-arrow-right"></i></el-link>
          <span class="money">{{ shangzhang }}</span>
        </div>
      </div>
    </div>
    <!-- echarts图表 -->
    <div class="echartsStyle">
      <div id="trend" :style="{ width: '100%', height: '500px' }"></div>
      <el-select v-model="selectValue" placeholder="请选择" @change="clickSelect" class="selectStyle">
        <el-option
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import highcharts from 'highcharts';
import highchartsNoData from 'highcharts/modules/no-data-to-display.js';
import request from '@/found/utils/request';
import { unitConvert } from '../../../components/moneyNumber.js';

highchartsNoData(highcharts);

export default {
  data() {
    return {
      // 看板部分
      surplus: null, // 红包余额
      fachu: null, // 昨日红包发出
      shangzhang: null, // 昨日红包上账
      dailyAverageday: null, // 日均发出
      statisticsDay: null, // 统计天数
      predictDay: null, // 预测天数
      // echarts 部分
      dataArr: [],
      selectOptions: [{
        value: '7',
        label: '最近7天',
      }, {
        value: '30',
        label: '最近30天',
      }],
      selectValue: '',
      parameterDay: 7,
      dataRow: {},
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    parameterDay() {
      this.getData();
    },
  },
  methods: {
    getData() {
      request
        .get('/cps/v1/redPacket/balance/findByStatisticsDay', { statisticsDay: this.parameterDay })
        .then((res) => {
          if (!res.success) return;
          this.dataRow = res.result;
          this.setData(this.dataRow);
          highcharts.chart(document.getElementById('trend'), {
            lang: {
              noData: '暂无数据',
            },
            noData: {
              style: { fontSize: '18px', fontWeight: '400' },
            },
            chart: {
              type: 'column',
            },
            title: {
              text: '红包发放趋势',
              align: 'left',
            },
            xAxis: {
              type: 'category',
              categories: this.tidyXdata(this.dataRow.amountList),
              labels: {
                enabled: false,
              },
            },
            yAxis: {
              title: {
                text: '金额 (元)',
              },
            },
            tooltip: {
              headerFormat: '<table>',
              pointFormat: `<tr>
            <td style="color:{series.color};padding:0">{series.name}: </td>
            <td style="padding:0"><b>{point.y:.1f} (元)</b></td>
          </tr>`,
              footerFormat: '</table>',
              useHTML: true,
            },
            plotOptions: {
              column: {
                pointStart: 0,
                // borderWidth: 0,
              },
            },
            series: this.tidyData(this.dataRow.amountList),
            credits: {
              enabled: false, // 禁用版权信息
            },
          });
        });
    },
    // 看板部分
    setData(data) {
      console.log(data);
      this.surplus = unitConvert(data.balance);
      this.fachu = unitConvert(data.yesterdayAmount);
      this.shangzhang = unitConvert(data.yesterdayKeepBooks);
      this.dailyAverageday = unitConvert(data.averageAmount);
      this.statisticsDay = data.statisticsDay;
      this.predictDay = data.predictDay;
    },
    // highcharts数据
    tidyData(rowData) {
      const arrList = rowData.map((v) => {
        const col = v;
        if (col) {
          const str = col.day.split('-');
          const newStr = `${str[1]}/${str[2]}`;
          col.name = newStr;
          col.data = [col.amount];
        }
        return col;
      });
      console.log(arrList);
      return arrList;
    },
    // X轴数据
    tidyXdata(rowData) {
      const xdata = [];
      rowData.map((v) => {
        const col = v;
        if (col) {
          const str = col.day.split('-');
          const newStr = `${str[1]}/${str[2]}`;
          xdata.push(newStr);
        }
        return col;
      });
      console.log(xdata);
      return xdata;
    },
    clickSelect(val) {
      this.parameterDay = val;
    },
    changeFlow() {
      this.$router.push('/cps_web/rights/changeflow');
    },
    redpackBill() {
      this.$router.push('/cps_web/rights/billing');
    },
  },
};
</script>

<style lang="less" scoped>
.content{
  min-width: 985px;
}
.box {
  height: 276px;
}
.textFont {
  width: 100%;
  margin: 30px 0 24px;
  padding-left: 27px;
  span {
    font-family: PingFang-SC-ExtraLight;
    font-size: 16px;
    color: #666666;
    .textColor {
      color: #0e5dc3;
      font-weight: 500;
    }
  }
}
.header-box {
  width: 100%;
  height: 168px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    position: relative;
    margin: 0 27px;
    width: 33.5%;
    height: 168px;
    font-family: PingFangSC-Regular;
    color: #333333;
    border-radius: 4px;
    // background-image: linear-gradient(-45deg, #eef6ff 0%, #ffffff 100%);
    box-shadow: 0px 0px 10px 0px rgba(14, 93, 195, 0.1);
    padding: 20px 18px 0 40px;
    .test {
      font-size: 16px;
      height: 24px;
      line-height: 24px;
      // margin: 30px 0 12px 40px;
    }
    .money {
      display: block;
      height: 58px;
      line-height: 58px;
      font-family: DINPro-Medium;
      font-size: 50px;
      font-weight: 500;
      // margin-left: 40px;
    }
    .detailViews {
      position: absolute;
      top: 22px;
      right: 18px;
      font-family: PingFangSC-Regular;
      font-size: 14px !important;
    }
    .el-link {
      font-size: 16px;
    }
  }
  .yve {
    background: url("~@/found/assets/img/cpsimg/yve.png") no-repeat;
    background-size: cover;
    .money {
      color: #0e5dc3;
    }
  }
  .fachu {
    background: url("~@/found/assets/img/cpsimg/fachu.png") no-repeat;
    background-size: cover;
    .money,
    .detailViews {
      color: #f79435 !important;
    }
  }
  .shangzhang {
    background: url("~@/found/assets/img/cpsimg/shangzhang.png") no-repeat;
    background-size: cover;
    .money,
    .detailViews {
      color: #28b795 !important;
    }
  }
}

.echartsStyle{
  position: relative;
  .selectStyle{
    position: absolute;
    top: 0;
    right: 27px;
  }
}
</style>
