/*
 * @Author: 冯杰
 * @Date: 2021-08-26 10:54:33
 * @LastEditTime: 2021-09-29 16:26:05
 * @FileName: 客户积分余额列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import DetailForm from './components/detailForm.js';
import EditForm from './components/edit.js';
import Tree from '../../../../components/tree.vue';

export default {
  name: 'displayTask',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/integral/integral/findByConditions',
      requestType: 'GET',
      isCalculateHeight: false,
    };
  },
  components: {
    Modal,
    DetailForm,
    EditForm,
    Tree,
  },
  // watch: {
  //   searchFormData(v) {
  //     console.log(v);
  //   },
  // },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'view') {
        // 查看
        this.formName = 'DetailForm';
        this.formConfig = row;
        this.openFull();
      } else if (val.code === 'export') {
        // 导出
      } else if (val.code === 'edit') {
        // 编辑
        this.modalConfig.title = '编辑';
        this.formName = 'EditForm';
        this.formConfig = row;
        this.modalConfig.height = '500px';
        this.modalConfig.width = '800px';
        this.openModal();
      } else if (val.code === 'detail') {
        // 明细
        this.formName = 'DetailForm';
        this.formConfig = row;
        this.openFull();
      }
    },
    treeValue(val) {
      this.formData.orgCode = val;
      this.searchFormData.orgCode = val;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('pointsBalanceTable');
  },
};
