var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "textFont" }, [
        _c("span", [
          _vm._v("最近" + _vm._s(_vm.statisticsDay) + "天，日均发出"),
          _c("span", { staticClass: "textColor" }, [
            _vm._v(_vm._s(_vm.dailyAverageday) + "元"),
          ]),
          _vm._v("，余额预计还能发"),
          _c("span", { staticClass: "textColor" }, [
            _vm._v(_vm._s(_vm.predictDay) + "天"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "header-box" }, [
        _c("div", { staticClass: "yve" }, [
          _c("span", { staticClass: "test" }, [_vm._v("红包余额(元)")]),
          _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.surplus))]),
        ]),
        _c(
          "div",
          { staticClass: "fachu" },
          [
            _c("span", { staticClass: "test" }, [_vm._v("昨日红包发出(元)")]),
            _c(
              "el-link",
              { staticClass: "detailViews", on: { click: _vm.changeFlow } },
              [
                _vm._v("查看明细 "),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]
            ),
            _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.fachu))]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "shangzhang" },
          [
            _c("span", { staticClass: "test" }, [_vm._v("昨日红包上账(元)")]),
            _c(
              "el-link",
              { staticClass: "detailViews", on: { click: _vm.redpackBill } },
              [
                _vm._v("查看明细 "),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]
            ),
            _c("span", { staticClass: "money" }, [
              _vm._v(_vm._s(_vm.shangzhang)),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "echartsStyle" },
      [
        _c("div", {
          style: { width: "100%", height: "500px" },
          attrs: { id: "trend" },
        }),
        _c(
          "el-select",
          {
            staticClass: "selectStyle",
            attrs: { placeholder: "请选择" },
            on: { change: _vm.clickSelect },
            model: {
              value: _vm.selectValue,
              callback: function ($$v) {
                _vm.selectValue = $$v
              },
              expression: "selectValue",
            },
          },
          _vm._l(_vm.selectOptions, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }