var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "selectRef",
      staticClass: "styleSelect",
      attrs: { placeholder: "请选择所属组织", clearable: "" },
      on: {
        clear: function () {
          return _vm.clearOrg()
        },
      },
      model: {
        value: _vm.selected.selectCode,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "selectCode", $$v)
        },
        expression: "selected.selectCode",
      },
    },
    [
      _c("el-option", {
        staticClass: "hiddent",
        attrs: {
          value: _vm.selected.selectCode,
          label: _vm.selected.selectName,
        },
      }),
      _c("el-input", {
        staticClass: "styleInput",
        attrs: { placeholder: "输入关键字进行过滤" },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.treedata,
          props: _vm.defaultProps,
          "filter-node-method": _vm.filterNode,
        },
        on: { "node-click": _vm.topicNodeClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }