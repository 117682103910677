var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormHeader", {
        staticClass: "form-header",
        attrs: { visible: true, title: "经销商信息" },
      }),
      _c(
        "el-form",
        { attrs: { inline: true, "label-width": "125px", size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "经销商编码" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "经销商编码" },
                model: {
                  value: _vm.row.dealerCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.row, "dealerCode", $$v)
                  },
                  expression: "row.dealerCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "经销商名称" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "经销商名称" },
                model: {
                  value: _vm.row.dealerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.row, "dealerName", $$v)
                  },
                  expression: "row.dealerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "未上账费用" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "未上账费用" },
                model: {
                  value: _vm.row.unBilledFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.row, "unBilledFee", $$v)
                  },
                  expression: "row.unBilledFee",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("FormHeader", {
        staticClass: "form-header",
        attrs: { visible: true, title: "货补信息" },
      }),
      _c(
        "el-form",
        {
          attrs: {
            inline: true,
            model: _vm.formInline,
            "label-width": "125px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "总上账费用金额" } },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "总上账费用金额" },
                model: {
                  value: _vm.formInline.totalFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "totalFee", $$v)
                  },
                  expression: "formInline.totalFee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上账备注" } },
            [
              _c("el-input", {
                attrs: { placeholder: "上账备注" },
                model: {
                  value: _vm.formInline.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "remark", $$v)
                  },
                  expression: "formInline.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-plus", type: "text" },
                    on: { click: _vm.addProduct },
                  },
                  [_vm._v("添加货补商品")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        {
          attrs: {
            data: _vm.tableData,
            "edit-config": { trigger: "click", mode: "cell" },
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { type: "seq", title: "序号", width: "60" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "materialCode", title: "商品编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "materialName", title: "商品名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "materialTypeName", title: "商品类型" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "amount",
              title: "费用金额",
              "edit-render": {
                name: "$input",
                events: { input: this.feeInput },
                props: { type: "number", min: 0 },
              },
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "操作",
              width: "100",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "delBtn",
                        on: {
                          click: function ($event) {
                            return _vm.delRow(rowIndex)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "productRef",
        on: { onGetSelect: _vm.productConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }