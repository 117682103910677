/*
 * @Author: 冯杰
 * @Date: 2021-08-26 11:44:35
 * @LastEditTime: 2021-09-04 18:28:23
 * @FileName: 编辑表单
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'editForm',
      fields: [
        'participatorName',
        'participatorCode',
        'participatorType',
        'channel',
        'orgCode',
      ],
    };
  },
  mounted() {

  },
  methods: {
    formComplete() {
      this.disabled(true, this.fields);
      const dataRow = this.formConfig;
      this.setValue({
        ...dataRow,
      });
    },
    setRule(v) {
      const item = v;
      if (item.field === 'orgCode') {
        item.props = {
          ...item.props,
          restful: '/mdm/baseTreeController/customerOrgTree',
          props: {
            // 树形层级对应
            children: 'children',
            title: 'name',
            key: 'id',
            value: 'code',
          },
        };
      }
      if (item.field === 'amount') {
        item.props = {
          ...item.props,
          min: 1,
        };
      }
      return item;
    },
    submit() {
      const formData = this.getFormData();
      if (formData.type === 'reduce') {
        formData.amount = -formData.amount;
      }
      formData.amount = Number(formData.amount);
      formData.triggerAction = formData.type;
      if (formData) {
        const url = '/cps/v1/integral/detail';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
