/*
 * @Author: chenlei
 * @Date: 2020-08-17 19:55:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-16 11:30:39
 * @Description:
 */
export function setPrecision(value, precision, isround) {
  const _precision = typeof precision !== 'undefined' && Number(precision)
    ? Number(precision)
    : 2;
  const _value = Number(value);
  if (typeof isround === 'undefined') {
    return _value.toFixed(_precision);
  }
  return (
    // eslint-disable-next-line no-restricted-properties
    Math.round((_value * Math.pow(10, _precision)) / Math.pow(10, _precision)).toFixed(_precision)
  );
}

// 千分位
function thousandSeparator(value, precision, callback) {
  let integral = ''; // 整数部分
  let decimal = ''; // 小数部分
  let isMinus = false;
  let outputCharacters; // 最终结果字符
  let parts = [];

  if (typeof value === 'undefined' || value === '' || value === null) {
    // eslint-disable-next-line no-unused-expressions
    callback && callback('不能为空,请输入正确的金额!');
    return '';
  }

  let _value = value.toString();

  if (_value.match(/^\\-\d+(\.\d+)?$/) !== null) {
    isMinus = true;
    _value = _value.substring(1, _value.length);
  }

  if (_value.match(/[^,.\d]/) !== null) {
    // eslint-disable-next-line no-unused-expressions
    callback && callback('字符串中的字符无效!');
    return '';
  }

  if (
    _value.match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/)
    == null
  ) {
    // eslint-disable-next-line no-unused-expressions
    callback && callback('请输入正确的金额!');
    return value;
  }

  // 精度处理
  _value = setPrecision(_value, precision);

  _value = _value.replace(/,/g, ''); // 移出逗号分隔符
  _value = _value.replace(/^0+/, ''); // 移出首字符为0

  // 判断最大
  if (Number(_value) > Number.MAX_VALUE) {
    // eslint-disable-next-line no-unused-expressions
    callback && callback('输入的金额超过最大限制!');
    return Number.MAX_VALUE;
  }

  // 整数和小数 区分
  parts = _value.split('.');
  if (parts.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    integral = parts[0];
    // eslint-disable-next-line prefer-destructuring
    decimal = parts[1];
    // 截取小数部分两位
    // 19.10.28 处理是多少位就返回多少位
    decimal = decimal.substr(0);
  } else {
    // eslint-disable-next-line prefer-destructuring
    integral = parts[0];
    decimal = '';
  }

  // 整数部分
  if (Number(integral) > 0) {
    const step = integral.length % 3;
    switch (step) {
      case 1:
        integral = `00${integral}`;
        break;
      case 2:
        integral = `0${integral}`;
        break;
      // no default
    }
    outputCharacters = integral
      .match(/\d{3}/g)
      .join(',')
      .replace(/^0+/, '');
  } else if (integral === '') {
    outputCharacters = '0';
  }

  // 小数部分
  if (decimal !== '') {
    outputCharacters += `.${decimal}`;
  }

  if (isMinus) {
    outputCharacters = `-${outputCharacters}`;
  }

  return outputCharacters;
}

export default thousandSeparator;
