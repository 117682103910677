<!--
 * @Autor: yzr
 * @Date: 2021-08-10 10:27:33
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-04 11:35:44
 * @Description: 经销商费用余额
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form/form.vue';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/cost/dealer/findByConditions',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
      isCalculateHeight: false,
    };
  },
  props: {
    acountType: String,
  },
  watch: {
    async acountType(v) {
      if (v === '1') this.requestUrl = '/cps/v1/cost/dealerBill/findByConditions';
      else this.requestUrl = '/cps/v1/cost/dealer/findByConditions';
      await this.getConfigList('cps-rights-dealerAcount-list');
      if (v === '1') {
        this.configs.toolBarConfig.shift();
      }
    },
  },
  async created() {
    // 未上账
    await this.getConfigList('cps-rights-dealerAcount-list');
    if (this.acountType === '1') {
      this.configs.toolBarConfig.shift();
    }
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (this.acountType === '0') {
        if (
          rowData.field === 'billType'
          || rowData.field === 'billedFee'
          || rowData.field === 'createAccount'
          || rowData.field === 'createTime'
        ) rowData.visible = false;
      }
      if (this.acountType === '1') {
        if (
          rowData.field === 'unBilledFee'
          || rowData.field === 'modifyAccount'
          || rowData.field === 'modifyTime'
        ) rowData.visible = false;
      }

      return rowData;
    },
    modalClick({ val, row }) {
      this.modalConfig = {
        title: '货补上账',
      };
      switch (val.code) {
        case 'goods_acount':
          this.formConfig.row = row;
          this.formConfig.acountType = 'acount';
          this.openFull();
          break;
        case 'discount_acount_inline':
          this.modalConfig.title = '折扣上账';
          this.formConfig.row = [row];
          this.formConfig.acountType = 'discount';
          this.openFull();
          break;
        case 'discount_acount_header':
          this.modalConfig.title = '折扣上账';
          this.formConfig.row = this.selectRow;
          this.formConfig.acountType = 'discount';
          this.openFull();
          break;
        default:
          break;
      }
    },
    disable(type) {
      let url = '/cps/v1/template/enable';
      if (type) url = '/cps/v1/template/disable';
      request.patch(url, this.selection).then((res) => {
        if (!res.success) return;
        this.$message.success(res.message);
        this.getList();
      });
    },
    clickVisible({ val, row }) {
      if (this.acountType === '1') return false;
      return true;
    },
  },
};
</script>
