<!--
 * @Autor: yzr
 * @Date: 2021-08-25 17:02:47
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-30 10:44:16
 * @Description: 上账公共弹窗
-->
<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import AcountForm from './acountForm.vue';
import DiscountForm from './discountForm.vue';

formCreate.component('acountTable', AcountForm);
formCreate.component('discountTable', DiscountForm);

export default {
  name: 'Form',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cps-rights-dealerAcount-form',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'acountTable') {
        item.props = {
          ...item.props,
          row: this.formConfig.acountType === 'acount' ? this.formConfig.row : {},
        };
      }
      if (item.field === 'discountTable') {
        item.props = {
          ...item.props,
          tableData: this.formConfig.acountType === 'discount' ? this.formConfig.row : [],
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    formComplete() {
      if (this.formConfig.acountType === 'acount') this.hiddenFields(true, ['discountTable']);
      if (this.formConfig.acountType === 'discount') this.hiddenFields(true, ['acountTable']);
    },
    // 表单提交
    submit() {
      const AcountInstance = this.fApi.el('acountTable');
      const DiscountInstance = this.fApi.el('discountTable');
      const { row } = this.formConfig;
      let params = [];

      if (this.formConfig.acountType === 'acount') {
        params = [{
          ...params,
          billType: 1,
          dealerCode: row.dealerCode,
          dealerName: row.dealerName,
          channel: row.channel,
          orgCode: row.orgCode,
          orgName: row.orgName,
          totalFee: AcountInstance.formInline.totalFee,
          remark: AcountInstance.formInline.remark,
          billDetail: AcountInstance.tableData,
          sourceCode: '123',
        }];

        if (AcountInstance.formInline.totalFee > AcountInstance.row.unBilledFee) return this.$message.error('费用余额不足');
      } else {
        DiscountInstance.tableData.forEach((item) => {
          const data = item;
          data.payType = '2';
          return data;
        });
        row.forEach((item) => {
          params.push({
            billType: 2,
            dealerCode: item.dealerCode,
            dealerName: item.dealerName,
            channel: item.channel,
            orgCode: item.orgCode,
            orgName: item.orgName,
            totalFee: DiscountInstance.formInline.totalFee,
            remark: DiscountInstance.formInline.remark,
            billDetail: DiscountInstance.tableData,
            sourceCode: '123',
          });
        });
      }

      params.forEach((item) => {
        item.billDetail.forEach((child) => {
          const data = child;
          delete data.id;
          return data;
        });
      });

      // console.log(params);
      request.post('/cps/v1/cost/dealerBill', params).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.$message.success(res.message);
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
};
</script>
