/*
 * @Author: 冯杰
 * @Date: 2021-08-17 09:54:55
 * @LastEditTime: 2021-10-14 09:52:32
 * @FileName: 红包上账记录
 */
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'TerminalSMSList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps-mobile/h5/red/sms/findByConditions',
      requestType: 'GET',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
      searchFormData: {},
      formData: {},
    };
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
    },
  },
  async created() {
    await this.getConfigList('terminal_SMS_list');
  },
};
