// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/found/assets/img/cpsimg/yve.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/found/assets/img/cpsimg/fachu.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".header-box[data-v-118e0ea5] {\n  min-width: 1219px;\n  width: 100%;\n  height: 238px;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n.header-box div[data-v-118e0ea5] {\n  margin: 0 27px;\n  width: 33.3%;\n  height: 168px;\n  font-family: PingFangSC-Regular;\n  color: #333333;\n  border-radius: 4px;\n  background-image: linear-gradient(-45deg, #eef6ff 0%, #ffffff 100%);\n  box-shadow: 0px 0px 10px 0px rgba(14, 93, 195, 0.1);\n}\n.header-box div span[data-v-118e0ea5] {\n  display: block;\n  font-size: 16px;\n}\n.header-box div .test[data-v-118e0ea5] {\n  height: 24px;\n  line-height: 24px;\n  margin: 30px 0 12px 40px;\n}\n.header-box div .money[data-v-118e0ea5] {\n  height: 58px;\n  line-height: 58px;\n  font-family: DINPro-Medium;\n  font-size: 50px;\n  font-weight: 500;\n  margin-left: 40px;\n}\n.header-box div .el-link[data-v-118e0ea5] {\n  font-size: 16px;\n}\n.header-box .yve[data-v-118e0ea5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.header-box .yve .money[data-v-118e0ea5] {\n  color: #0e5dc3;\n}\n.header-box .fachu[data-v-118e0ea5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.header-box .fachu .money[data-v-118e0ea5] {\n  color: #f79435;\n}\n", ""]);
// Exports
module.exports = exports;
