/*
 * @Author: 冯杰
 * @Date: 2021-08-26 10:05:27
 * @LastEditTime: 2021-09-06 14:21:02
 * @FileName: 积分变动流水列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Protocol from './protocol.js';
import Tree from '../../../components/tree.vue';

export default {
  name: 'customerRedpacketList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/integral/detailResp/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Protocol,
    Tree,
  },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.recordCode,
      };
      if (val.code === 'export') {
        // 导出
      } else if (val.code === 'export-all') {
        // 全部导出
      } else if (val.code === 'relevance-protocol') {
        // 跳转分利协议模板
        this.$router.push({
          name: 'modules',
          params: {
            templateCode: row.templateCode,
          },
        });
      } else if (val.code === 'associated-order') {
        // 查看关联订单
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('integralflowList');
  },
};
