/*
 * @Author: 冯杰
 * @Date: 2021-08-15 14:34:34
 * @LastEditTime: 2021-10-09 10:48:20
 * @FileName: 客户红包余额
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Tree from '../../../components/tree.vue';
import ChangeDetails from './change.js';

export default {
  name: 'customerRedpacketList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/redPacket/custom/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Tree,
    ChangeDetails,
  },
  mounted() {
    this.$refs.xTable.setAllCheckboxRow(true);
  },
  methods: {
    beforeSetSearch(val) {
      const item = val;
      if (item.field === 'orgName') {
        item.typeName = 'Tree';
        item.type = 'customSearch';
      }
      return item;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'export') {
        // 导出
      } else if (val.code === 'detail') {
        // 明细
        this.modalConfig.title = '红包变动明细';
        this.formName = 'ChangeDetails';
        this.propsObjInData.id = row.id;
        this.modalConfig.height = '500px';
        this.modalConfig.width = '1000px';
        this.openModal();
      } else if (val.code === 'view') {
        // 跳转到终端详情页
        this.$router.push({
          name: 'terminal',
          params: { terminalCode: row.participatorCode },
        });
      }
    },
    setColumn(v) {
      const rowData = v;
      return rowData;
    },
    treeValue(val) {
      this.formData.orgCode = val;
      this.searchFormData.orgCode = val;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('customerRedpacketList');
  },
};
