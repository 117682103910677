/*
 * @Author: 冯杰
 * @Date: 2021-08-16 14:56:50
 * @LastEditTime: 2021-09-06 14:21:12
 * @FileName: 红包变动流水
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Protocol from './protocol.js';

export default {
  name: 'customerRedpacketList',
  extends: TablePage,
  data() {
    return {
      // requestUrl: '/cps/v1/redPacket/award/findByConditions',
      requestUrl: '/cps/v1/xf/red/packet/report/findDetailByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Protocol,
  },
  mounted() {
    this.$refs.xTable.setAllCheckboxRow(true);
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'triggerAction') {
        rowData.search = 'true';
        rowData.title = '触发动作';
        rowData.type = 'select';
        rowData.apiUrl = '/cps/v1/policy/action/find';
        rowData.methods = 'get';
        rowData.optionsKey = {
          label: 'dictDesc',
          value: 'dictValue',
        };
        rowData.isSearch = true;
        rowData.paramsName = 'dictDesc';
      }
      return rowData;
    },
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.recordCode,
      };
      if (val.code === 'export') {
        // 导出
      } else if (val.code === 'relevance-protocol') {
        // 跳转到分利协议模板
        this.$router.push({
          name: 'modules',
          params: {
            name: 'modules',
            templateCode: row.templateCode,
          },
        });
        this.$emit('onClose');
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('changeflowList');
  },
};
