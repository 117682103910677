/*
 * @Author: 冯杰
 * @Date: 2021-08-13 14:06:15
 * @LastEditTime: 2021-08-26 10:59:32
 * @FileName: 客户积分余额表单
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import Balance from './balance.vue';
import BalanceTable from './table/index.js';

formCreate.component('Balance', Balance);
formCreate.component('BalanceTable', BalanceTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'pointsBalanceForm',
    };
  },
  methods: {
    formComplete() {

    },
  },
};
