/*
 * @Author: 冯杰
 * @Date: 2021-08-17 09:54:55
 * @LastEditTime: 2021-10-14 09:52:32
 * @FileName: 红包上账记录
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import addBilling from './form/index.js';
import { unitConvert } from '../../../components/moneyNumber.js';

export default {
  name: 'BillingRecordList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/redPacket/keepBooks/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    addBilling,
  },
  mounted() {
    this.$refs.xTable.setAllCheckboxRow(true);
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'export') {
        // 导出
      } else if (val.code === 'add') {
        // 新增
        this.modalConfig.title = '新增';
        this.formName = 'addBilling';
        this.modalConfig.height = '500px';
        this.modalConfig.width = '1000px';
        this.openModal();
      }
    },
    afterGetList() {
      const editTableData = this.tableData;
      editTableData.map((v) => {
        const item = v;
        item.amount = unitConvert(item.amount);
        return item;
      });
      this.tableData = editTableData;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('billingRecord');
  },
};
