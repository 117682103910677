/*
 * @Author: 冯杰
 * @Date: 2021-08-24 10:18:49
 * @LastEditTime: 2021-08-31 17:05:53
 * @FileName: 红包上账记录/新增页面
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'addBilling',
    };
  },
  methods: {
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/cps/v1/redPacket/keepBooks';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
