/*
 * @Author: 冯杰
 * @Date: 2021-08-15 10:31:34
 * @LastEditTime: 2021-08-26 10:45:52
 * @FileName: 金额处理
 */
import thousandSeparator from './thousandSeparator';

export function strNumSize(tempNum) {
  const stringNum = tempNum.toString();
  const index = stringNum.indexOf('.');
  let newNum = stringNum;
  if (index !== -1) {
    newNum = stringNum.substring(0, index);
  }
  return newNum.length;
}

// 金额单位格式化
export function unitConvert(num, min = 7) {
  let numb = num;
  // 整数超过多少才格式化
  if (!numb && numb !== 0) {
    return numb;
  }
  numb = Number(numb).toString();
  const arr = numb.split('.');
  if (!arr[0] || arr[0].length < min) {
    return thousandSeparator(numb);
  }
  const moneyUnits = ['', '万', '亿', '万亿'];
  const dividend = 10000;
  let curentNum = parseFloat(numb); // 转换数字
  let curentUnit = moneyUnits[0]; // 转换单位
  //   for (let i = 0; i < 4; i++) {
  for (let i = 0; i < 4; i += 1) {
    curentUnit = moneyUnits[i];
    if (strNumSize(curentNum) < 5) {
      break;
    }
    curentNum /= dividend;
  }
  const m = { num: 0, unit: '' };
  m.num = curentNum.toFixed(2);
  m.unit = curentUnit;
  return m.num + m.unit;
}
