<!--
 * @Autor: yzr
 * @Date: 2021-08-25 16:59:59
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-28 16:32:09
 * @Description: 货补上账弹窗
-->

<template>
  <div>
    <FormHeader class="form-header" :visible='true' title="经销商信息" />
    <el-form :inline="true" label-width='125px' size='small'>
      <el-form-item label="经销商编码">
        <el-input disabled v-model="row.dealerCode" placeholder="经销商编码"></el-input>
      </el-form-item>
      <el-form-item label="经销商名称">
        <el-input disabled v-model="row.dealerName" placeholder="经销商名称"></el-input>
      </el-form-item>
      <el-form-item label="未上账费用">
        <el-input disabled v-model="row.unBilledFee" placeholder="未上账费用"></el-input>
      </el-form-item>
    </el-form>

    <FormHeader class="form-header" :visible='true' title="货补信息" />
    <el-form :inline="true" :model="formInline" label-width='125px' size='small'>
      <el-form-item label="总上账费用金额">
        <el-input v-model="formInline.totalFee" disabled placeholder="总上账费用金额"></el-input>
      </el-form-item>
      <el-form-item label="上账备注">
        <el-input v-model="formInline.remark" placeholder="上账备注"></el-input>
      </el-form-item>
    </el-form>

    <vxe-toolbar>
      <template #buttons>
        <el-button icon="el-icon-plus" type="text" @click="addProduct">添加货补商品</el-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      :data="tableData"
      :edit-config="{trigger: 'click', mode: 'cell'}"
    >
      <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
      <vxe-table-column field="materialCode" title="商品编码"></vxe-table-column>
      <vxe-table-column field="materialName" title="商品名称"></vxe-table-column>
      <vxe-table-column field="materialTypeName" title="商品类型"></vxe-table-column>
      <vxe-table-column field="amount" title="费用金额" :edit-render="{name: '$input', events: { input: this.feeInput }, props: { type: 'number', min: 0 }}"></vxe-table-column>
      <vxe-table-column title="操作" width="100" fixed='right' align='center'>
        <template #default="{ rowIndex }">
          <a class="delBtn" @click="delRow(rowIndex)">删除</a>
        </template>
      </vxe-table-column>
    </vxe-table>

    <SelectConfig ref="productRef" @onGetSelect="productConfirm" />
  </div>
</template>

<script>
import FormHeader from '@/found/components/form/components/form_header.vue';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    FormHeader,
    SelectConfig,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      formInline: {
        totalFee: 0,
        remark: '',
      },
      tableData: [],
    };
  },
  methods: {
    feeInput(e) {
      this.formInline.totalFee = e.data.reduce((total, item) => total + (item.amount ? Number(item.amount) : 0), 0);
    },
    addProduct() {
      const params = {
        functionCode: 'cps-rights-dealerAcount-product',
        data: this.tableData,
        selectionList: [],
        idKey: 'materialCode',
        noReset: true,
        paramData: {
        },
      };
      this.$refs.productRef.openSelectModal(params);
    },
    productConfirm(params) {
      console.log('商品弹框确认带出的数据', params);
      const data = [];
      params.forEach((item) => {
        data.push({
          materialCode: item.materialCode,
          materialName: item.materialName,
          productLevelCode: item.productLevelCode,
          productLevelName: item.productLevelName,
          materialTypeName: item.materialTypeName,
          payType: '1',
        });
      });
      this.tableData = data;
    },
    delRow(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.form-header {
  margin: 18px 0;
}
.delBtn {
  color: #ff6e55e6 !important;
}
</style>
