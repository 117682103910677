<!--
 * @Author: 冯杰
 * @Date: 2021-08-10 13:54:39
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-29 14:10:46
 * @FilePath: \crm2.0\found\views\cps\views\execute\package\table\components\tree.vue
-->
<template>
  <el-select v-model="selected.selectCode" placeholder="请选择所属组织" @clear="() => clearOrg()" clearable ref="selectRef" class="styleSelect">
    <el-option :value="selected.selectCode" :label="selected.selectName" class="hiddent"></el-option>
    <el-input placeholder="输入关键字进行过滤" v-model="filterText" class="styleInput"></el-input>
      <el-tree
        :data="treedata"
        :props="defaultProps"
        @node-click="topicNodeClick"
        :filter-node-method="filterNode"
        ref="tree"
      ></el-tree>
  </el-select>
</template>

<script>
import request from '@/found/utils/request';

export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getUrl();
  },

  methods: {
    clearOrg() {
      this.selected.selectCode = '';
      this.selected.selectName = '';
      this.$parent.$parent.$parent.treeValue('');
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getUrl() {
      const params = { enableStatus: '009' };
      request.post('/mdm/baseTreeController/orgTree', params).then((res) => {
        if (!res.success) return;
        this.treedata = res.result;
      });
    },
    topicNodeClick(data, node) {
      if (node.children) {
        // todo
        console.log(this.$refs);
      } else {
        // todo
        this.selected.selectCode = data.code;
        this.selected.selectName = data.name;
        this.$refs.selectRef.blur();
        this.$emit('treeValue', this.selected.selectCode);
        this.$parent.$parent.$parent.treeValue(this.selected.selectCode);
      }
    },
  },

  data() {
    return {
      treedata: [], // 树形下拉数据数组
      selected: {
        selectCode: '',
        selectName: '',
      }, // 选中数据数组
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      filterText: '',
    };
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown__item{
    padding: 0;
}
/deep/ .el-select-dropdown__item.selected {
    font-weight: 400;
}
/deep/ .el-select-dropdown__list{
  width: 395px;
}
.styleSelect{
  width: 395px;
}
.hiddent{
  // visibility: hidden;
  display: none;
}
.styleInput{
  width: 60%;
  margin: 5px 5px 5px 24px;
}
</style>
