<!--
 * @Author: 冯杰
 * @Date: 2021-08-13 14:12:07
 * @LastEditTime: 2021-09-13 13:40:28
 * @FileName: 客户积分余额看板
-->
<template>
  <div class="header-box">
      <div class="yve">
        <span class="test">累计发放积分总额</span>
        <span class="money">{{surplus}}</span>
      </div>
      <!-- <div class="fachu">
        <span class="test">客户剩余积分总额</span>
        <span class="money">{{fachu}}</span>
      </div> -->
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      surplus: '', // 累计发放积分总额
      fachu: '', // 客户剩余积分总额
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      request
        .get('/cps/v1/integral/balance/findBalance')
        .then((res) => {
          if (!res.success) return;
          const obj = res.result;
          this.surplus = obj.cumulativeIntegral;
          this.fachu = obj.residualIntegral;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header-box {
  min-width: 1219px;
  width: 100%;
  height: 238px;
  display: flex;
  justify-content: space-around;
  align-items: center;
    div {
      margin: 0 27px;
      width: 33.3%;
      height: 168px;
      font-family: PingFangSC-Regular;
      color: #333333;
      border-radius: 4px;
      background-image: linear-gradient(-45deg, #eef6ff 0%, #ffffff 100%);
      box-shadow: 0px 0px 10px 0px rgba(14, 93, 195, 0.1);
      span {
        display: block;
        font-size: 16px;
      }
      .test {
        height: 24px;
        line-height: 24px;
        margin: 30px 0 12px 40px;
      }
      .money {
        height: 58px;
        line-height: 58px;
        font-family: DINPro-Medium;
        font-size: 50px;
        font-weight: 500;
        margin-left: 40px;
      }
      .el-link {
        font-size: 16px;
      }
    }
    .yve {
      background: url("~@/found/assets/img/cpsimg/yve.png");
      .money {
        color: #0e5dc3;
      }
    }
    .fachu {
      background: url("~@/found/assets/img/cpsimg/fachu.png");
      .money {
        color: #f79435;
      }
    }

}
</style>
