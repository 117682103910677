/*
 * @Author: 冯杰
 * @Date: 2021-08-26 11:38:28
 * @LastEditTime: 2021-09-07 21:25:53
 * @FileName: 积分变动情况列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'displayTask',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/integral/detailResp/findByConditions',
      requestType: 'GET',
      params: {
        integralId: this.rowData.id,
      },
    };
  },
  components: {
    Modal,
  },
  props: {
    rowData: Object,
  },
  methods: {
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('PointsChanges');
    console.log(this.rowData);
  },
};
