/*
 * @Autor: yzr
 * @Date: 2021-08-10 10:09:34
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-23 15:40:36
 * @Description: 终端费用上账
 */
import TerminalAcount from './TerminalAcount.vue';

export default TerminalAcount;
