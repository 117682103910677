/*
 * @Author: 冯杰
 * @Date: 2021-08-26 11:21:38
 * @LastEditTime: 2021-09-07 21:24:07
 * @FileName: 明细表单
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import PointsChanges from './changes.js';

formCreate.component('PointsChanges', PointsChanges);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: true,
        submitAudit: false,
      },
      formFunctionCode: 'detailForm',
      fields: [
        'participatorCode',
        'participatorName',
        'balance',
        'phone',
        'participatorType',
        'channel',
        'orgName',
      ],
    };
  },
  props: {
    formConfig: Object,
  },
  mounted() {
    // this.getData();
  },
  methods: {
    getData() {
      request.get(`/cps/v1/integral/integral/${this.formConfig.id}`).then((res) => {
        if (!res.success) return;
        console.log(res);
        this.setValue({
          ...res.result,
        });
      });
    },
    setRule(v) {
      const item = v;
      if (item.field === 'PointsChanges') {
        item.props = {
          ...item.props,
          rowData: this.formConfig,
        };
      }
      return item;
    },
    formComplete() {
      // 禁用字段
      this.disabled(true, this.fields);
      // 发请求获取数据
      this.getData();
    },
  },
};
