/*
 * @Autor: yzr
 * @Date: 2021-08-10 10:09:18
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-25 14:23:56
 * @Description: 经销商费用上账
 */
import Content from './content.vue';

export default Content;
