<!--
 * @Autor: yzr
 * @Date: 2021-08-10 10:27:33
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-31 16:05:08
 * @Description: 经销商费用余额
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/cost/terminalBill/findByConditions',
      params: {
      },
      formConfig: {},
      modalConfig: {
      },
    };
  },
  async created() {
    await this.getConfigList('cps-rights-terminalAcount-list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      return rowData;
    },
    modalClick({ val, row }) {
      this.modalConfig = {
        title: '协议模板配置',
      };
      switch (val.code) {
        case 'add':
          this.openFull();
          break;
        case 'view':
          this.formConfig.id = row.id;
          this.openFull();
          break;
        case 'enable':
          this.disable(false);
          break;
        case 'disable':
          this.disable(true);
          break;
        default:
          break;
      }
    },
    disable(type) {
      let url = '/cps/v1/template/enable';
      if (type) url = '/cps/v1/template/disable';
      request.patch(url, this.selection).then((res) => {
        if (!res.success) return;
        this.$message.success(res.message);
        this.getList();
      });
    },
    clickVisible({ val, row }) {
      return true;
    },
  },
};
</script>
