/*
 * @Author: 冯杰
 * @Date: 2021-08-07 18:02:40
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-06 14:21:08
 * @FilePath: 关联协议列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'ProtocolList',
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      requestUrl: '/cps/v1/agreement/agreement/findListByConditions',
      requestType: 'GET',
      params: {
        recordCode: this.propsObj.code,
      },
    };
  },
  components: {
    Modal,
  },
  watch: {
    propsObj(val) {
      console.log(this.propsObj);
    },
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {
        ...this.formConfig,
      };
      if (val.code === 'view') {
        // 跳转到分利协议模板
        this.$router.push({
          path: '/cps_web/treety/modules',
          query: {
            templateCode: row.templateCode,
          },
        });
        this.$emit('onClose');
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('protocolList');
  },
};
