<!--
 * @Autor: yzr
 * @Date: 2021-08-25 14:18:39
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-01 15:33:45
 * @Description: 包装table组件
-->

<template>
  <div style="height: 100%">
    <el-radio-group v-model="acountType">
      <el-radio-button label="0">未上账</el-radio-button>
      <el-radio-button label="1">已上账</el-radio-button>
    </el-radio-group>
    <DealerAcount :acountType="acountType" />
  </div>
</template>

<script>
import DealerAcount from './DealerAcount.vue';

export default {
  name: 'content',
  components: {
    DealerAcount,
  },
  data() {
    return {
      acountType: '0',
    };
  },
};
</script>
